import axios from "axios";
import { V_URL } from "../../../BaseUrl";
import toast from "react-hot-toast";

export const DownloadPdf = ({ apiMethod, url, body, is_admin = false }) => {
    let myurl
    let token
    if (is_admin) {
        myurl = `${V_URL}/admin/${url}`;
    } else {
        myurl = `${V_URL}/user/${url}`;
    }
    if (is_admin) {
        token = localStorage.getItem('VA_TOKEN');
    } else {
        token = localStorage.getItem('PAY_USER_TOKEN');
    }

    axios({
        method: apiMethod,
        data: body,
        url: myurl,
        headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + token },
    }).then((response) => {
        if (response?.data?.success === true) {
            const fileUrl = response.data.data.file;
            window.open(fileUrl, '_blank');
            toast.success(response?.data.message);
        } else {
            toast.error(response?.data.message);
        }
    }).catch((error) => {
        toast.error(error?.response?.data.message);
    })
}