import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../../Include/Header';
import Sidebar from '../../../Include/Sidebar';
import PageHeader from '../../Components/Breadcrumbs/PageHeader';
import { getDrawing } from '../../../../../Store/Erp/Planner/Draw/Draw';
import DrawingTable from '../../Components/DrawingTable/DrawingTable';
import SubmitButton from '../../Components/SubmitButton/SubmitButton';
import MultiFdModal from '../../Components/MultiFdModal/MultiFdModal';
import { getUserMultiNdtMaster } from '../../../../../Store/MutipleDrawing/MultiNDT/getUserMultiNdtMaster';
import { getMultipleIssueAcc } from '../../../../../Store/MutipleDrawing/IssueAcc/MultipleIssueAcc';
import MultiFdTable from '../../Components/MultiFdModal/MultiFdTable';
import toast from 'react-hot-toast';
import { V_URL } from '../../../../../BaseUrl';
import axios from 'axios';
import { updateFDOfferTable } from '../../../../../Store/MutipleDrawing/MultiFd/updateFDOfferTable';
import { checkFdDrawingGrid } from '../../../../../helper/hideDrawing';

const ManageMultiFd = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [disable, setDisable] = useState(false);
    const [search, setSearch] = useState('');
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setlimit] = useState(10);
    const [entity, setEntity] = useState([]);
    const [issueFd, setIssueFd] = useState([]);
    const [finalArr, setFinalArr] = useState([]);
    const [drawId, setDrawId] = useState('');
    const [showItem, setShowItem] = useState(false);
    const [submitArr, setSubmitArr] = useState([]);
    const data = location.state;
    const [checkDr, setCheckDr] = useState({ ndt: [], issueAcceptance: [] });
    const [matchedData, setMatchedData] = useState([]);
    const [unmatchedData, setUnmatchedData] = useState([]);

    useEffect(() => {
        dispatch(getDrawing());
        dispatch(getUserMultiNdtMaster({ status: 3 }));
        dispatch(getMultipleIssueAcc());
    }, []);

    const drawData = useSelector((state) => state?.getDrawing?.user?.data);
    const ndtData = useSelector((state) => state?.getUserMultiNdtMaster?.user?.data);
    const issueAcc = useSelector((state) => state?.getMultipleIssueAcc?.user?.data);

    useEffect(() => {
        const filterIssue = issueAcc?.filter((is) => is?.isFd === true);
        setIssueFd(filterIssue);
        const issAccDrawIDs = [...new Set(filterIssue?.flatMap(is => is?.items?.map((it) => it?.drawing_id?._id)))];

        const ndtDrawIds = [...new Set(ndtData?.flatMap(report => report?.items?.map(item => item.drawing_id)))];
        const mergedDrawIds = [...new Set([...issAccDrawIDs, ...ndtDrawIds])];
        const filteredDrawData = drawData?.filter(draw => mergedDrawIds.includes(draw._id));
        setEntity(filteredDrawData);

        setCheckDr({
            ndt: ndtData || [],
            issueAcceptance: filterIssue || [],
        });
    }, [drawData, ndtData, issueAcc]);

    const checkFdDrawing = async () => {
        const response = await checkFdDrawingGrid(checkDr);

        const { matchData, unmatchData } = response;

        const matchDrawIDs = [...new Set(matchData?.flatMap(is =>
            is?.items?.map(it => it?.drawing_id)
        ))];


        const unMatchDrawIDs = [...new Set(unmatchData?.flatMap(it => it?.items?.map((un) => un?.drawing_id?._id)))];

        const mergedDrawIds = [...new Set([...matchDrawIDs])];
        const mergedUnMatchDrIds = [...new Set([...unMatchDrawIDs])];

        const filteredMatchData = drawData?.filter(draw => mergedDrawIds.includes(draw._id));

        const filteredUnMatchData = drawData?.filter(draw => mergedUnMatchDrIds.includes(draw._id));

        setMatchedData(filteredMatchData);
        setUnmatchedData(filteredUnMatchData);
    }

    useEffect(() => {
        checkFdDrawing();
    }, [checkDr, drawData]);

    const commentsData = useMemo(() => {
        // let computedComments = entity;
        let computedComments = matchedData || [];
        setTotalItems(computedComments?.length);
        if (search) {
            computedComments = computedComments.filter(
                (dr) =>
                    dr?.drawing_no.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    dr?.rev?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    dr?.assembly_no?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    dr?.assembly_quantity?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    dr?.unit?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    dr?.sheet_no?.toLowerCase()?.includes(search?.toLowerCase())
            );
        }
        return computedComments?.slice(
            (currentPage - 1) * limit,
            (currentPage - 1) * limit + limit
        );
    }, [search, limit, currentPage, entity, matchedData]);

    const handleAddToArr = (drId) => {
        setShowItem(true);
        setDrawId(drId._id);
    }

    const handleSubmit = () => {
        let updatedData = submitArr;
        let isValid = true;

        if (updatedData?.length === 0) {
            toast.error('Please add drawing sections');
            return;
        }

        updatedData.forEach(item => {
            if (!item.required_dimension) {
                isValid = false;
                toast.error(`Please select required dimension for ${item.grid_id.grid_no}`);
            }
        });
        if (!isValid) {
            return;
        }

        const filteredData = submitArr.map((item) => ({
            _id: item._id,
            drawing_id: item.drawing_id._id,
            grid_id: item.grid_id._id,
            required_dimension: item.required_dimension,
            fd_balanced_grid_qty: item.fd_balanced_grid_qty,
            fd_used_grid_qty: item.fd_used_grid_qty,
            remarks: item.remarks || '',
            ndt_master_id: item.ndt_master_id ? item.ndt_master_id?.map((e) => e) : [],
            fd_offer_no: item.fd_offer_no,
            issue_acc_id: item.issue_acc_id ? item.issue_acc_id?.map((e) => e) : []
        }));

        setDisable(true);
        const myurl = `${V_URL}/user/manage-multi-fd`;
        const formData = new URLSearchParams();
        formData.append('offered_by', localStorage.getItem('PAY_USER_ID'));
        formData.append('project', localStorage.getItem('PAY_USER_PROJECT_NAME'));
        formData.append('items', JSON.stringify(filteredData));
        formData.append('issue_acc_id', localStorage.getItem('issue_acc_ids'));
        formData.append('ndt_master_id', localStorage.getItem('ndt_master_ids'));
        formData.append('is_new', localStorage.getItem('IS_NEW_FD'));
        axios({
            method: "post",
            url: myurl,
            data: formData,
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('PAY_USER_TOKEN') },
        }).then((response) => {
            if (response?.data?.success === true) {
                toast.success(response?.data?.message);
                const updatedData = new URLSearchParams();
                updatedData.append('items', JSON.stringify(filteredData));
                updatedData.append('fd_master_id', response.data?.data?._id);
                dispatch(updateFDOfferTable({ updatedData })).then((response) => {
                    if (response?.payload?.success === true) {
                        navigate('/user/project-store/final-dimension-offer-management');
                        localStorage.removeItem('issue_acc_ids');
                        localStorage.removeItem('ndt_master_ids');
                        localStorage.setItem('IS_NEW_FD', 'true')
                    }
                });
            } else {
                toast.error(response.data.message);
            }
        }).catch((error) => {
            toast.error(error.response.data.message);
        }).finally((() => { setDisable(false) }));
    }

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const handleOpen = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <>
            <div className={`main-wrapper ${isSidebarOpen ? "slide-nav" : ""}`}>
                <Header handleOpen={handleOpen} />
                <Sidebar />

                <div className="page-wrapper">
                    <div className="content">
                        <PageHeader breadcrumbs={[
                            { name: "Dashboard", link: "/user/project-store/dashboard", active: false },
                            { name: "Final Dimension Inspection Offer List", link: "/user/project-store/final-dimension-offer-management", active: false },
                            { name: `${data?._id ? 'Edit' : 'Add'} Final Dimension Inspection Offer`, active: true }
                        ]} />

                        <DrawingTable
                            tableTitle={'Drawing List'}
                            commentsData={commentsData}
                            handleAddToIssueArr={handleAddToArr}
                            currentPage={currentPage}
                            limit={limit}
                            setlimit={setlimit}
                            totalItems={totalItems}
                            setCurrentPage={setCurrentPage}
                            setSearch={setSearch}
                            data={data}
                        />

                        <MultiFdTable
                            data={data}
                            finalArr={finalArr}
                            setSubmitArr={setSubmitArr}
                        />

                        <SubmitButton finalReq={data?.items} link='/user/project-store/final-dimension-offer-management'
                            disable={disable} handleSubmit={handleSubmit} buttonName={'Generate Final Dimension Offer'} />
                    </div>
                </div>
            </div>
            <MultiFdModal
                showItem={showItem}
                handleCloseModal={() => setShowItem(false)}
                title={'Drawing Grid List'}
                setFinalArr={setFinalArr}
                issueFd={issueFd}
                ndtData={ndtData}
                drawId={drawId}
            />
        </>
    )
}

export default ManageMultiFd