import { M_CON, PAINT, PLAN, PRODUCTION, QA, QC, WELD } from "../../../../BaseUrl";


export const menuAccessConfig = {
    Unit: [PLAN, M_CON],
    ItemCategory: [PLAN, M_CON],
    Transport: [PLAN, M_CON],
    InventoryLocation: [PLAN, M_CON],
    JointType: [PLAN, PRODUCTION, QC, WELD, QA],
    NDT: [PLAN, PRODUCTION, QC, WELD, QA],
    Contractor: [PLAN, PRODUCTION],
    PaintManufacturer: [PLAN, M_CON, PRODUCTION, QC, QA, PAINT],
    PaintingSystem: [PLAN, PRODUCTION, QC, QA, PAINT],
    ProjectLocation: [PLAN],
    WPS: [PLAN, PRODUCTION, QC, WELD, QA],
    QualifiedWelder: [PLAN, PRODUCTION, WELD, QA],
    ProcedureSpecification: [PLAN, PRODUCTION, QC, WELD, QA, PAINT],
    Party: [PLAN, M_CON, QA, QC],
    SectionDetails: [PLAN, M_CON, QA],
    ProjectMaterialStore: [PLAN, M_CON, QC, QA, PRODUCTION],
    MaterialReceiving: [M_CON, QC],
    MaterialQC: [PLAN, M_CON, QC, QA],
    Drawing: [],
    ExecutionOffer: [PLAN, PRODUCTION],
    ClearanceQC: [PLAN, QC, QA],
    NDT_DROP: [PRODUCTION, QA, QC, PLAN],
    NDT_MASTER: [PRODUCTION, QA, QC, PLAN],
    NDT_PROCESS: [QC],
    IRNDispatch_PAINT: [PLAN, PRODUCTION, QA, PAINT, QC],
    ISR: [PLAN, PRODUCTION, QA, QC],
    PAINT_DISPATCH: [PLAN, PRODUCTION, QA, PAINT],
    PAINT_MASTER: [PLAN, PRODUCTION, QA, QC, PAINT],

    IRN_AFTER: [PLAN, PRODUCTION, QA, QC, M_CON],

    BILL: [PLAN],
    DPR: [PLAN, QC, QA, PRODUCTION],
}