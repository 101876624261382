import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import { V_URL } from "../../../BaseUrl";

export const getDpr = createAsyncThunk("/party/getDpr", async () => {
  try {
    const projectId = localStorage.getItem("U_PROJECT_ID");
    const myurl = `${V_URL}/user/get-grid-dpr?project=${projectId}`;
    const response = await axios({
      method: "get",
      url: myurl,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("PAY_USER_TOKEN"),
      },
    });
    const data = response.data;
    if (data.success === true) {
      return data;
    } else {
      throw new Error(data);
    }
  } catch (error) {
    console.log(error, "error");
    toast.error(error.response.data.message);
    return error;
  }
});

const getDprSlice = createSlice({
  name: "getDpr",
  initialState: {
    user: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDpr.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDpr.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getDpr.rejected, (state, action) => {
        state.user = null;
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default getDprSlice.reducer;
