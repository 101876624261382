export const notesData = [
    {
        id: 1,
        title: "NDT Process",
        description: "The NDT process allows only one-time rejection for methods such as Ultrasonic Testing (UT), Radiographic Testing (RT), Magnetic Particle Testing (MPT), and Liquid Penetrant Testing (LPT)."
    },
    {
        id: 2,
        title: "NDT Process",
        description: "In the NDT process, the first offer must be for the re-offer drawing, followed by the original submission."
    }
];
