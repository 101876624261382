import { configureStore } from "@reduxjs/toolkit";

// Admin
import adminLoginSlice from "../Store/Admin/Login/Login";
import adminForgetPasswordSlice from "../Store/Admin/Login/ForgetPassword";
import adminOtpSlice from "../Store/Admin/Login/Otp";
import resetAdminPasswordSlice from "../Store/Admin/Login/ResetPassword";

import getBankSlice from "../Store/Admin/Payroll/Bank/Bank";
import getAdminBankSlice from "../Store/Admin/Payroll/Bank/AdminBank";
import addBankSlice from "../Store/Admin/Payroll/Bank/ManageBank";

import getShiftSlice from "../Store/Admin/Payroll/Shift/Shift";
import getAdminShiftSlice from "../Store/Admin/Payroll/Shift/AdminShift";
import addShiftSlice from "../Store/Admin/Payroll/Shift/ManageShift";

import getGroupSlice from "../Store/Admin/Payroll/Group/getGroup";
import getAdminGroupSlice from "../Store/Admin/Payroll/Group/AdminGroup";
import addGroupSlice from "../Store/Admin/Payroll/Group/AdminGroup";

import getDesignationSlice from "../Store/Admin/Payroll/Designation/Designation";
import getAdminDesignationSlice from "../Store/Admin/Payroll/Designation/AdminDesgination";
import addDesignationSlice from "../Store/Admin/Payroll/Designation/ManageDesignation";

import getAuthPersonSlice from "../Store/Admin/Payroll/AuthPerson/AuthPerson";
import getAdminAuthSlice from "../Store/Admin/Payroll/AuthPerson/AdminAuthPerson";
import addAuthPersonSlice from "../Store/Admin/Payroll/AuthPerson/ManageAuthPerson";

import getSkillSlice from "../Store/Admin/Payroll/Skill/Skill";
import getAdminSkillSlice from "../Store/Admin/Payroll/Skill/AdminSkill";
import addSkillSlice from "../Store/Admin/Payroll/Skill/AddSkill";

import getEmployeeTypeSlice from "../Store/Admin/Payroll/EmployeeType/EmployeeType";
import getAdminEmployeeTypeSlice from "../Store/Admin/Payroll/EmployeeType/AdminEmployeeType";
import addEmployeeTypeSlice from "../Store/Admin/Payroll/EmployeeType/ManageEmployeeType";

import getDepartmentSlice from "../Store/Admin/Payroll/Department/Department";
import getAdminDepartmentSlice from "../Store/Admin/Payroll/Department/AdminDepartment";
import addDepartmentSlice from "../Store/Admin/Payroll/Department/ManageDepartment";

import getYearSlice from "../Store/Admin/Payroll/Year/Year";
import getAdminYearSlice from "../Store/Admin/Payroll/Year/AdminYear";
import addYearSlice from "../Store/Admin/Payroll/Year/ManageYear";

// admin Store
import getErpRoleSlice from "../Store/Admin/ErpRole/ErpRole";
import adminGetPartySlice from "../Store/Admin/Party/GetParty";
import adminGetEmployeeSlice from "../Store/Admin/Employee/Employee";
import getFirmSlice from "../Store/Admin/Firm/Firm";

import AdminContractorMasterSlice from "./Admin/Contractor/AdminContractorMaster";

import adminGetPartyTagSlice from "../Store/Admin/PartyTag/AdminPartyTag";
import adminGetPartyGroupSlice from "../Store/Admin/PartyTag/AdminPartyGroup";

import getAdminItemDataSlice from "../Store/Admin/Item/getAdminItem";

// admin main store
import getAdminPRSlice from "./Admin/Transaction/GetAdminPurchaseRequest";
import getSinglePRSlice from "./Admin/Transaction/GetOnePurchaseRequest";
import getAdminPOSlice from "./Admin/Transaction/GetAdminPurchaseOrder";
import getSinglePOSlice from "./Admin/Transaction/GetOnePurchaseOrder";
import getAdminPUSlice from "./Admin/Transaction/GetAdminPurchaseRecieving";
import getSinglePUSlice from "./Admin/Transaction/GetOnePurchaseRecieving";
import getAdminPURSlice from "./Admin/Transaction/GetAdminPurchaseReturn";
import getSinglePURSlice from "./Admin/Transaction/GetOnePurchaseReturn";
import getAdmiIssueSlice from "./Admin/Transaction/GetAdminIssue";
import getSingleIssSlice from "./Admin/Transaction/GetOneIssue";
import getAdmiIssueReturnSlice from "./Admin/Transaction/GetAdminIssueReturn";
import getSingleIsrSlice from "./Admin/Transaction/GetOneIssueReturn";
import getAdminMainStockSlice from "./Admin/Transaction/GetAdminStock";

// get PMS stock list
import getAdminPMSStockSlice from "./Admin/PMS/GetPMSStock";

// get admin dashboard
import getAdmindeshboardSlice from "./Admin/Dashboard/GetAminDashboard";

import getAdminProjectLocationSlice from "./Admin/PMS/GetProjectLocation";

// get admin projects
import getAdminProjectSlice from "./Admin/Project/GetAdminProject";
import GetProjectAttendanceSlice from "./Admin/Project/GetProjectAttendance";

// Store ========================================================================

import userForgetPasswordSlice from "../Store/Store/Login/UserForget";
import AddPurchaseRequestSlice from "../Store/Store/PurchaseRequest/ManagePurchaseRequest";
import userOtpSlice from "../Store/Store/Login/UserOtp";
import resetUserPasswordSlice from "../Store/Store/Login/resetPassword";

import getUnitSlice from "../Store/Store/StoreMaster/Unit/Unit";
import getAdminUnitSlice from "../Store/Store/StoreMaster/Unit/AdminUnit";
import addUnitSlice from "../Store/Store/StoreMaster/Unit/ManageUnit";

import getCategorySlice from "../Store/Store/StoreMaster/Category/Category";
import getAdminCategorySlice from "../Store/Store/StoreMaster/Category/AdminCategory";
import addCategorySlice from "../Store/Store/StoreMaster/Category/ManageCategory";

import getTransportSlice from "../Store/Store/StoreMaster/Transport/Transport";
import getAdminTransportSlice from "../Store/Store/StoreMaster/Transport/AdminTransport";
import addTransportSlice from "../Store/Store/StoreMaster/Transport/ManageTransport";

import getLocationSlice from "../Store/Store/StoreMaster/InventoryLocation/Location";
import getAdminLocationSlice from "../Store/Store/StoreMaster/InventoryLocation/AdminLocation";
import addLocationSlice from "../Store/Store/StoreMaster/InventoryLocation/ManageLocation";
import getPartySlice from "../Store/Store/Party/Party";
import getAdminPartySlice from "../Store/Store/Party/AdminParty";
import addPartySlice from "../Store/Store/Party/ManageParty";
import getPartyGroupSlice from "../Store/Store/StoreMaster/PartyGroup/PartyGroup";
import getAdminPartyGroupSlice from "../Store/Store/StoreMaster/PartyGroup/PartyAdminGroup";
import addPartyGroupSlice from "../Store/Store/StoreMaster/PartyGroup/ManageParty";
import getUserDepartmentSlice from "../Store/Store/StoreMaster/Department/Department";
import getUserEmployeeSlice from "../Store/Store/StoreMaster/Employee/Employee";
import getPartyTagSlice from "../Store/Store/StoreMaster/PartyTag/PartyTag";
import getStoreAuthPersonSlice from "../Store/Store/StoreMaster/AuthPerson/AuthPerson";
import getAdminStoreAuthSlice from "../Store/Store/StoreMaster/AuthPerson/AdminAuthPerson";
import addStoreAuthPersonSlice from "../Store/Store/StoreMaster/AuthPerson/ManageAuthPerson";
import getItemSlice from "../Store/Store/Item/Item";
import getAdminItemSlice from "../Store/Store/Item/AdminItem";
import addItemSlice from "../Store/Store/Item/ManageItem";
import getProjectSlice from "../Store/Store/Project/Project";
import getStoreEmployeeSlice from "../Store/Store/Employee/Employee";
import getAdjustmentSlice from "../Store/Store/Adjustment/getAdjustment";
// import getItemStockSlice from '../Store/Store/Stock/getStock';
import getStoreDashboardSlice from "../Store/Store/Dashboard/Dashboard";
import getTransactionItemSlice from "../Store/Store/TransactionItem/TransactionItem";
import getOrderSlice from "../Store/Store/MainStore/PurchaseOrder/getPurchaseOrder";
import getPurchaseRequestSlice from "../Store/Store/PurchaseRequest/GetRequest";
import getSinglePurchaseRrequestSlice from "../Store/Store/PurchaseRequest/GetSinglePurchaseRrequest";
import getRequestAdminSlice from "../Store/Store/PurchaseRequest/GetRequestAdmin";
import getSingleAdminItemSlice from "../Store/Store/PurchaseRequest/GetSingleAdminItem";
import getOrderReturnSlice from "../Store/Store/Order/Return";

import getGenTagSlice from "../Store/Store/GenralMaster/TagGenMaster";
import getItemSummarySlice from "../Store/Store/Report/ItemSummary";
import getItemLedgerSlice from "../Store/Store/Report/ItemLedger";
import getReorderItemsSlice from "../Store/Store/Report/ReOrderItems";
// Main Store With Linking  ==============================================================

import getPurchaseRecievingSlice from "./Store/MainStore/PurchaseRecieving/GetPurchaseRecieving";
import getPurchaseRecievingItemsSlice from "./Store/MainStore/PurchaseRecieving/GetPurchaseRecievingItems";
import getPoNumberSlice from "./Store/MainStore/PurchaseRecieving/GetPoNo";
import getPoItemsSlice from "./Store/MainStore/PurchaseRecieving/GetPoitems";
import addPuSlice from "./Store/MainStore/PurchaseRecieving/ManagePu";

import getPurchaseReturnSlice from "./Store/MainStore/PurchaseReturn/GetPurchaseReturn";
import getPurchaseReturnItemsSlice from "./Store/MainStore/PurchaseReturn/GetPurchaseReturnItems";
import getChallanNoSlice from "./Store/MainStore/PurchaseReturn/GetChallanNumbers";
import getBillNoSlice from "./Store/MainStore/PurchaseReturn/GetBillNumbers";
import getPRItemsSlice from "./Store/MainStore/PurchaseReturn/GetPRItems";
import getPUNumberSlice from "./Store/MainStore/PurchaseReturn/getPUno";

import getPassSlice from "./Store/MainStore/Issue/GetPass";
import getIssueSlice from "./Store/MainStore/Issue/GetIssue";
import addIssueSlice from "./Store/MainStore/Issue/ManageIssue";
import getIssueItemsSlice from "./Store/MainStore/Issue/GetIssueItems";

import getIssueReturnSlice from "./Store/MainStore/IssueReturn/GetIssueReturn";
import getIssueReturnItemsSlice from "./Store/MainStore/IssueReturn/GetIssueReturnItems";
import getIssueChallanSlice from "./Store/MainStore/IssueReturn/GetIssueChallanNo";
import getIssueNoSlice from "./Store/MainStore/IssueReturn/GetIssueNo";
import getIssueGetPassSlice from "./Store/MainStore/IssueReturn/GetIssuePassNo";
import getIssueReturnListsSlice from "./Store/MainStore/IssueReturn/GetIssueReturnLists";
import addIssueReturnSlice from "./Store/MainStore/IssueReturn/ManageIssueReturn";

import getPrSlice from "./Store/MainStore/PurchaseOrder/GetPR";
import getPrItemsSlice from "./Store/MainStore/PurchaseOrder/GetPRItems";
import getSingleOrderSlice from "./Store/MainStore/PurchaseOrder/GetSinglePo";

//  ERp ================================================================================
// Planner -----------------------------------------------------

import getDrawingSlice from "../Store/Erp/Planner/Draw/Draw";
import getUserProfileSlice from "../Store/Store/Profile/Profile";
import getUserIssueSlice from "../Store/Store/Issue/Issue";
import getRequestSlice from "../Store/Store/Request/getRequest";
import getUserDrawTrasactionSlice from "../Store/Store/TransactionItem/getDrawTransaction";
import getUserAdminDrawSlice from "../Store/Erp/Planner/Draw/UserAdminDraw";
import getUserIssueRequestSlice from "../Store/Store/Issue/IssueRequest";
import getUserOfferSlice from "../Store/Store/Offer/getUserOffer";
import getOneIssueSlice from "../Store/Store/Issue/GetOneIssue";
import transactionReturnSlice from "../Store/Store/Issue/TransactionReturn";

import getReleseNoteSlice from "../Store/Erp/ReleseNote/ReleseNote";

// Material Coordinator APIS ==========================================================================

import getUserIssueAcceptanceSlice from "../Store/Store/Issue/IssueAcceptance";

// Execution =============================
import getUserFitupSlice from "../Store/Store/Execution/getUserFitup";
import getUserWeldVisualSlice from "../Store/Store/Execution/getUserWeldVisual";

import getUserJointTypeSlice from "../Store/Store/JointType/JointType";
import getUserNdtMasterSlice from "../Store/Store/Ndt/NdtMaster";
import getUserContractorSlice from "../Store/Store/ContractorMaster/ContractorMaster";

import getUserPaintSystemSlice from "../Store/Store/PaintSystem/PaintSystem";
import getUserWpsMasterSlice from "../Store/Store/WpsMaster/WpsMaster";
import getUserWelderMasterSlice from "../Store/Store/WelderMaster/WelderMaster";
import getUserProcedureMasterSlice from "../Store/Store/Procedure/ProcedureMaster";
import getUserPaintManufactureSlice from "../Store/Store/PaintManufacture/PaintManufacture";

import getStockReportSlice from "../Store/Store/Stock/getStockReport";
import getStockReportListSlice from "../Store/Store/Stock/getStockReportList";

import getUserMainNdtMasterSlice from "../Store/Store/Ndt/MainNdtMaster";
import getUserNdtOfferSlice from "../Store/Store/Ndt/NdtOffer";

import getUserUtClearanceSlice from "../Store/Store/Ndt/UT/UtClearance";
import getUserRtClearanceSlice from "../Store/Store/Ndt/RT/RtClearance";
import getUserMptClearanceSlice from "../Store/Store/Ndt/MPT/MptClearance";

import getUserLptClearanceSlice from "../Store/Store/Ndt/LPT/LptClearance";

import getUserFinalDimensionSlice from "../Store/Store/Execution/getUserFinalDimension";
import getUserInspectionSummarySlice from "../Store/Store/InspectionSummary/GetInspectionSummary";
import getUserGenInspectionSummarySlice from "../Store/Store/InspectionSummary/GetGeneratedInsSummary";
import getDispatchNotesSlice from "../Store/MutipleDrawing/DispatchNote/GetDisptchNote";
import getMultiDispatchSlice from "../Store/MutipleDrawing/DispatchNote/GetMultiDispatch";

import getMultiSurfcaeSlice from "../Store/MutipleDrawing/MultiSurface/GetMultiSurface";
import getMultiSurfaceOfferSlice from "../Store/MutipleDrawing/MultiSurface/GetSurfaseOffer";

import getUserSurfaceSlice from "../Store/Erp/Painting/Surface/Surface";
import getGenMasterSlice from "./Store/GenralMaster/GenMaster";
import AddOrderSlice from "./Store/Order/ManageOrder";
import AddOrderReturnSlice from "./Store/Order/ManageOrderReturn";
import getSingleOrderReturnSlice from "./Store/Order/GetSingleOrderReturn";
import getMainStoreStockSlice from "../Store/Store/MainStore/MainStock";
import getMainStockSlice from "../Store/Store/Stock/getMainStock";
import getUserMioSlice from "../Store/Erp/Painting/Mio/GetMio";
import getUserFinalCoatingSlice from "../Store/Erp/Painting/FinalCoating/FinalCoating";
import getPackSlice from "../Store/Erp/Packing/Packing";

import getInvoiceSlice from "../Store/Erp/Invoice/Invoice";
import getDprSlice from "../Store/Erp/Dpr/Dpr";
import getPmsStockSlice from "./Store/PMSStock/PMS";

import getUserProjectLocationSlice from "../Store/Erp/ProjectLocation/ProjectLocation";

// Multiple Drawings =================================================================
import getMultipleIssueRequestSlice from "../Store/MutipleDrawing/IssueRequest/MultipleIssueRequest";
import getMultipleIssueAccSlice from "../Store/MutipleDrawing/IssueAcc/MultipleIssueAcc";
import getMultipleDrawItemsSlice from "../Store/MutipleDrawing/MultipleDrawing/MultipleDrawItems";
import updateMultiGridSlice from "../Store/MutipleDrawing/MultipleDrawing/UpdateGridBal";

import updateIssueAccGridSlice from "../Store/MutipleDrawing/IssueAcc/UpdateIssueAccGrid";
import getMultiFitupSlice from "../Store/MutipleDrawing/MultiFitup/getMultiFitup";

import getMultiWeldVisualSlice from "../Store/MutipleDrawing/MultiWeldVisual/getMultiWeldVisual";
import updateFitupGridSlice from "../Store/MutipleDrawing/MultiWeldVisual/UpdateFitupGrid";

import manageFitupOffTableSlice from "../Store/MutipleDrawing/MultiFitup/manageFitupOffTable";
import getFitupOfferTableSlice from "../Store/MutipleDrawing/MultiFitup/getFitupOfferTable";

import removeFitupOffTableSlice from "../Store/MutipleDrawing/MultiFitup/removeFitupOffertable";
import updateFitupOffTableSlice from "../Store/MutipleDrawing/MultiFitup/updateFitupOfferTable";

import getWeldOfferTableSlice from "../Store/MutipleDrawing/MultiWeldVisual/getWeldOfferTable";
import manageWeldOfferTableSlice from "../Store/MutipleDrawing/MultiWeldVisual/manageWeldTableOffer";
import updateWeldOffTableSlice from "../Store/MutipleDrawing/MultiWeldVisual/updateWeldOfferTable";
import removeWeldOffTableSlice from "../Store/MutipleDrawing/MultiWeldVisual/removeWeldOfferTable";

import updateNDTGridSlice from "../Store/MutipleDrawing/MultiNDT/UpdateNDTGrid";
import manageNDTOfferTableSlice from "../Store/MutipleDrawing/MultiNDT/manageNDTTableOffer";
import getNDTOfferTableSlice from "../Store/MutipleDrawing/MultiNDT/getNDTOffertable";
import removeNDTOffTableSlice from "../Store/MutipleDrawing/MultiNDT/removeNdtOfferTable";

import updateNDTOffTableSlice from "../Store/MutipleDrawing/MultiNDT/updateNDTOfferTable";
import getUserMultiNdtMasterSlice from "../Store/MutipleDrawing/MultiNDT/getUserMultiNdtMaster";

import getMultiNdtOfferSlice from "../Store/MutipleDrawing/MultiNDT/TestNdtOffer/MultiTestOfferList";

import getMultipleGridSlice from "../Store/MutipleDrawing/MultipleDrawing/MultipleGrid";
import getMultiRtClearanceSlice from "../Store/MutipleDrawing/MultiNDT/RtClearance/getMultiRtClearance";
import getMultiMptClearanceSlice from "../Store/MutipleDrawing/MultiNDT/MptClearance/getMultiMptClearance";
import getMultiLptClearanceSlice from "../Store/MutipleDrawing/MultiNDT/LptClearance/getMultiLptClearance";
import getMultiUtClearanceSlice from "../Store/MutipleDrawing/MultiNDT/UtClearance/getMultiUtClearance";

import manageFdTableSlice from "../Store/MutipleDrawing/MultiFd/manageFdTable";
import updateNdtGridSlice from "../Store/MutipleDrawing/MultiFd/updateNdtGrid";
import removeFdTableSlice from "../Store/MutipleDrawing/MultiFd/removeFdTable";
import getFdTableSlice from "../Store/MutipleDrawing/MultiFd/getFdTable";

import updateFDOfferTableSlice from "../Store/MutipleDrawing/MultiFd/updateFDOfferTable";
import getMultiFdSlice from "../Store/MutipleDrawing/MultiFd/getMultiFd";

import manageRTOfferTableSlice from "../Store/MutipleDrawing/MultiNDT/RtClearance/ManageRtOfferTable";
import getMultiRtOfferTableSlice from "../Store/MutipleDrawing/MultiNDT/RtClearance/RtOfferTable";
import removeRTTableSlice from "../Store/MutipleDrawing/MultiNDT/RtClearance/RemoveRTTable";
import manageRTMultiOfferSlice from "../Store/MutipleDrawing/MultiNDT/RtClearance/ManageRTMultiOffer";

import getMultiUTOfferTableSlice from "../Store/MutipleDrawing/MultiNDT/UtClearance/UtOfferTable";
import getMultiMPTOfferTableSlice from "../Store/MutipleDrawing/MultiNDT/MptClearance/MptOfferTable";
import getMultiLPTOfferTableSlice from "../Store/MutipleDrawing/MultiNDT/LptClearance/LptOfferTable";

// Paint
import getMultiDispatchPaintSlice from "../Store/MutipleDrawing/MultiSurface/GetMultiDispatchNotePaint";

//for MIO
import getMultiSurfaceMioSlice from "../Store/MutipleDrawing/MultiMIO/GetMultiSurfaceMio";
import getMultiSurfaceOfferMioSlice from "../Store/MutipleDrawing/MultiMIO/GetSurfaceMioOffer";
import getMultiMioSlice from "../Store/MutipleDrawing/MultiMIO/GetMultiMio";
import getMultiMioInsSlice from "../Store/MutipleDrawing/MultiMIO/GetMultiMioIns";

//for Final Coating
import getMioListSlice from "../Store/MutipleDrawing/MultiFinalCoat/GetMio";
import getMioFilterListSlice from "../Store/MutipleDrawing/MultiFinalCoat/GetMultiMio";
import getMultiFinalCoatSlice from "../Store/MutipleDrawing/MultiFinalCoat/GetMultiFinalCoat";
import GetFinalCoatInsSlice from "../Store/MutipleDrawing/MultiFinalCoat/GetFinalCoatOffer";

import manageIssueOffTableSlice from "../Store/MutipleDrawing/IssueRequest/manageIssueOfferTable";
import removeIssueOffTableSlice from "../Store/MutipleDrawing/IssueRequest/removeIssueOfferTable";
import getIssueOfferTableSlice from "../Store/MutipleDrawing/IssueRequest/getIssueOfferTable";
import updateIssueOffTableSlice from "../Store/MutipleDrawing/IssueRequest/updateIssueOfferTable";

// Release Note list
import GetMultiReleaseNoteSlice from "./MutipleDrawing/MultiReleaseNote/GetMultiReleaseNote";
import GetMultiGenReleaseNoteSlice from "../Store/MutipleDrawing/MultiReleaseNote/GetMultiGeneratedReleaseNote";

// packings list
import getMultiPackingSlice from "../Store/MutipleDrawing/MultiPacking/GetMultiPacking";
import getMultiPackingListSlice from "../Store/MutipleDrawing/MultiPacking/GetMultiPackingList";


import getLoginFirmSlice from "../Store/MutipleDrawing/Invoice/getLoginFirm";
import getLoginProjectSlice from "../Store/MutipleDrawing/Invoice/getLoginProject";

import getMultiInvoiceSlice from "../Store/MutipleDrawing/Invoice/getMultiInvoice";

import getMultiDrawingItemsSlice from "../Store/MutipleDrawing/MultipleDrawing/GetMultiGridItems";

import getMultiGridsSlice from "../Store/MutipleDrawing/MultipleDrawing/GetMultiGrids";
// =============================================================================================================================

export default configureStore({
  reducer: {
    login: adminLoginSlice,
    forgetPassword: adminForgetPasswordSlice,
    adminOtp: adminOtpSlice,
    resetAdminPassword: resetAdminPasswordSlice,
    adminGetParty: adminGetPartySlice,
    adminGetEmployee: adminGetEmployeeSlice,
    getFirm: getFirmSlice,

    // Admin APIS=========================================================================
    //====== Payroll

    getBank: getBankSlice,
    getAdminBank: getAdminBankSlice,
    addBank: addBankSlice,

    getShift: getShiftSlice,
    getAdminShift: getAdminShiftSlice,
    addShift: addShiftSlice,

    getGroup: getGroupSlice,
    getAdminGroup: getAdminGroupSlice,
    addGroup: addGroupSlice,

    getDesignation: getDesignationSlice,
    getAdminDesignation: getAdminDesignationSlice,
    addDesignation: addDesignationSlice,

    getAuthPerson: getAuthPersonSlice,
    getAdminAuth: getAdminAuthSlice,
    addAuthPerson: addAuthPersonSlice,

    getAdminSkill: getAdminSkillSlice,
    getSkill: getSkillSlice,
    addSkill: addSkillSlice,

    getEmployeeType: getEmployeeTypeSlice,
    getAdminEmployeeType: getAdminEmployeeTypeSlice,
    addEmployeeType: addEmployeeTypeSlice,

    getDepartment: getDepartmentSlice,
    getAdminDepartment: getAdminDepartmentSlice,
    addDepartment: addDepartmentSlice,

    getYear: getYearSlice,
    getAdminYear: getAdminYearSlice,
    addYearSlice: addYearSlice,

    adminGetPartyTag: adminGetPartyTagSlice,
    adminGetPartyGroup: adminGetPartyGroupSlice,

    getErpRole: getErpRoleSlice,

    // Admin Store
    getAdminItemData: getAdminItemDataSlice,
    getAdminContractor: AdminContractorMasterSlice,

    // admin main Store
    getAdminPurchaseRequest: getAdminPRSlice,
    getOnePR: getSinglePRSlice,
    getAdminPurchaseOrder: getAdminPOSlice,
    getOnePO: getSinglePOSlice,
    getAdminRecieving: getAdminPUSlice,
    getSinglePU: getSinglePUSlice,
    getAdminPurchaseReturn: getAdminPURSlice,
    getSinglePUR: getSinglePURSlice,
    getAdmiIssue: getAdmiIssueSlice,
    getSingleIss: getSingleIssSlice,
    getAdmiIssueReturn: getAdmiIssueReturnSlice,
    getSingleIsr: getSingleIsrSlice,
    getAdminStock: getAdminMainStockSlice,

    getAdminPMSStock: getAdminPMSStockSlice,

    getAdmindeshboard: getAdmindeshboardSlice,

    //get admin projects
    getAdminProject: getAdminProjectSlice,
    GetProjectAttendance: GetProjectAttendanceSlice,
    getAdminProjectLocation: getAdminProjectLocationSlice,

    // Store =================================================

    userForgetPasswordSlice: userForgetPasswordSlice,
    userOtp: userOtpSlice,
    resetUserPassword: resetUserPasswordSlice,

    getUnit: getUnitSlice,
    getAdminUnit: getAdminUnitSlice,
    addUnit: addUnitSlice,

    getCategory: getCategorySlice,
    getAdminCategory: getAdminCategorySlice,
    addCategory: addCategorySlice,

    getTransport: getTransportSlice,
    getAdminTransport: getAdminTransportSlice,
    addTransport: addTransportSlice,

    getLocation: getLocationSlice,
    getAdminLocation: getAdminLocationSlice,
    addLocation: addLocationSlice,

    getParty: getPartySlice,
    getAdminParty: getAdminPartySlice,
    addParty: addPartySlice,

    getPartyGroup: getPartyGroupSlice,
    getAdminPartyGroup: getAdminPartyGroupSlice,
    addPartyGroup: addPartyGroupSlice,

    getUserDepartment: getUserDepartmentSlice,
    getUserEmployee: getUserEmployeeSlice,

    getPartyTag: getPartyTagSlice,

    getStoreAuthPerson: getStoreAuthPersonSlice,
    getAdminStoreAuth: getAdminStoreAuthSlice,
    addStoreAuthPerson: addStoreAuthPersonSlice,

    getItem: getItemSlice,
    getAdminItem: getAdminItemSlice,
    addItem: addItemSlice,

    AddPurchaseRequestSlice: AddPurchaseRequestSlice,
    getPurchaseRequest: getPurchaseRequestSlice,
    getSinglePurchaseRrequest: getSinglePurchaseRrequestSlice,

    getRequestAdmin: getRequestAdminSlice,
    getSingleAdminItem: getSingleAdminItemSlice,

    //get gen Master
    getGenMaster: getGenMasterSlice,
    getGenTag: getGenTagSlice,

    //purchase order slice
    getVoucher: getPrSlice,
    getPrItems: getPrItemsSlice,
    addPurchaseorder: AddOrderSlice,
    getPurchaseorder: getOrderSlice,
    getSinglePurchaseorder: getSingleOrderSlice,

    //purchase recieving
    getPurchaseRecieving: getPurchaseRecievingSlice,
    getOnePrItems: getPurchaseRecievingItemsSlice,
    getPoNumbers: getPoNumberSlice,
    getPoItems: getPoItemsSlice,
    addPuItems: addPuSlice,

    //purchase return
    getPurchaseReturn: getPurchaseReturnSlice,
    getPurchaseReturnItems: getPurchaseReturnItemsSlice,
    getChallanNo: getChallanNoSlice,
    getBillNo: getBillNoSlice,
    getPRItems: getPRItemsSlice,
    getPUNo: getPUNumberSlice,

    //issue
    getPass: getPassSlice,
    addIssue: addIssueSlice,
    getIssue: getIssueSlice,
    getIssueItems: getIssueItemsSlice,
    transactionReturn: transactionReturnSlice,

    //issue Return
    getIssueReturn: getIssueReturnSlice,
    getIssueReturnItems: getIssueReturnItemsSlice,
    getIssueChallan: getIssueChallanSlice,
    getIssueNo: getIssueNoSlice,
    getIssueGetPassNo: getIssueGetPassSlice,
    getIssueReturnLists: getIssueReturnListsSlice,
    addIssueReturn: addIssueReturnSlice,

    addPurchaseorderReturn: AddOrderReturnSlice,
    getPurchaseReturnorder: getOrderReturnSlice,
    getSingleReturn: getSingleOrderReturnSlice,

    getProject: getProjectSlice,
    getStoreEmployee: getStoreEmployeeSlice,

    getAdjustment: getAdjustmentSlice,
    // getItemStock: getItemStockSlice,
    getStoreDashboard: getStoreDashboardSlice,

    getTransactionItem: getTransactionItemSlice,

    getItemSummary: getItemSummarySlice,
    getItemLedger: getItemLedgerSlice,
    getReorderItems: getReorderItemsSlice,

    // Erp =====================================================================
    //Planner ===============================
    getDrawing: getDrawingSlice,
    getUserProfile: getUserProfileSlice,
    getUserIssue: getUserIssueSlice,
    getRequest: getRequestSlice,
    getUserDrawTrasaction: getUserDrawTrasactionSlice,
    getUserAdminDraw: getUserAdminDrawSlice,
    getUserIssueRequest: getUserIssueRequestSlice,
    getUserOffer: getUserOfferSlice,
    getUserIssueAcceptance: getUserIssueAcceptanceSlice,
    getReleseNote: getReleseNoteSlice,

    //packing
    getPacking: getPackSlice,
    // Ececution ==============================================================
    getUserFitup: getUserFitupSlice,
    getUserWeldVisual: getUserWeldVisualSlice,

    getUserJointType: getUserJointTypeSlice,
    getUserNdtMaster: getUserNdtMasterSlice,
    getUserContractor: getUserContractorSlice,
    getUserPaintSystem: getUserPaintSystemSlice,

    getUserWpsMaster: getUserWpsMasterSlice,
    getUserWelderMaster: getUserWelderMasterSlice,
    getUserProcedureMaster: getUserProcedureMasterSlice,
    getUserPaintManufacture: getUserPaintManufactureSlice,

    getStockReport: getStockReportSlice,
    getStockReportList: getStockReportListSlice,

    getUserMainNdtMaster: getUserMainNdtMasterSlice,

    getUserNdtOffer: getUserNdtOfferSlice,

    getUserUtClearance: getUserUtClearanceSlice,
    getUserRtClearance: getUserRtClearanceSlice,

    getUserMptClearance: getUserMptClearanceSlice,
    getUserLptClearance: getUserLptClearanceSlice,

    getUserFinalDimension: getUserFinalDimensionSlice,
    getUserInspectionSummary: getUserInspectionSummarySlice,
    getUserGenInspectionSummary: getUserGenInspectionSummarySlice,
    getDispatchNotes: getDispatchNotesSlice,
    getMultiDispatch: getMultiDispatchSlice,
    getMainStoreStock: getMainStoreStockSlice,

    getMultiSurface: getMultiSurfcaeSlice,
    getMultiSurfaceOffer: getMultiSurfaceOfferSlice,

    getUserSurface: getUserSurfaceSlice,
    getMainStock: getMainStockSlice,
    getUserMio: getUserMioSlice,
    getUserFinalCoating: getUserFinalCoatingSlice,

    getInvoice: getInvoiceSlice,
    getDpr: getDprSlice,

    //PMS
    getPmsStock: getPmsStockSlice,

    getUserProjectLocation: getUserProjectLocationSlice,

    // Mutiple =================================================================================================

    getMultipleIssueRequest: getMultipleIssueRequestSlice,
    getMultipleIssueAcc: getMultipleIssueAccSlice,

    getMultipleDrawItems: getMultipleDrawItemsSlice,
    updateMultiGrid: updateMultiGridSlice,

    getMultipleGrid: getMultipleGridSlice,

    updateIssueAccGrid: updateIssueAccGridSlice,
    getMultiFitup: getMultiFitupSlice,
    manageFitupOffTable: manageFitupOffTableSlice,

    getMultiWeldVisual: getMultiWeldVisualSlice,
    updateFitupGrid: updateFitupGridSlice,

    getFitupOfferTable: getFitupOfferTableSlice,
    removeFitupOffTable: removeFitupOffTableSlice,
    updateFitupOffTable: updateFitupOffTableSlice,

    getWeldOfferTable: getWeldOfferTableSlice,
    manageWeldOfferTable: manageWeldOfferTableSlice,
    updateWeldOffTable: updateWeldOffTableSlice,
    removeWeldOffTable: removeWeldOffTableSlice,

    updateNDTGrid: updateNDTGridSlice,
    manageNDTOfferTable: manageNDTOfferTableSlice,
    getNDTOfferTable: getNDTOfferTableSlice,
    removeNDTOffTable: removeNDTOffTableSlice,
    updateNDTOfferTable: updateNDTOffTableSlice,
    getUserMultiNdtMaster: getUserMultiNdtMasterSlice,

    getMultiNdtOffer: getMultiNdtOfferSlice,

    getMultiRtClearance: getMultiRtClearanceSlice,
    getMultiMptClearance: getMultiMptClearanceSlice,
    getMultiLptClearance: getMultiLptClearanceSlice,
    getMultiUtClearance: getMultiUtClearanceSlice,

    manageFdTable: manageFdTableSlice,
    updateNdtGrid: updateNdtGridSlice,
    removeFdTable: removeFdTableSlice,
    getFdTable: getFdTableSlice,

    updateFDOfferTable: updateFDOfferTableSlice,

    getMultiFd: getMultiFdSlice,

    manageRTOfferTable: manageRTOfferTableSlice,
    getMultiRtOfferTable: getMultiRtOfferTableSlice,
    removeRTTable: removeRTTableSlice,
    manageRTMultiOffer: manageRTMultiOfferSlice,

    getMultiUTOfferTable: getMultiUTOfferTableSlice,
    getMultiMPTOfferTable: getMultiMPTOfferTableSlice,
    getMultiLPTOfferTable: getMultiLPTOfferTableSlice,

    //  Paint
    getMultiDispatchPaint: getMultiDispatchPaintSlice,

    // slices for Mio
    getMultisurfaceMio: getMultiSurfaceMioSlice,
    getMultiSurfaceOfferMio: getMultiSurfaceOfferMioSlice,
    getMultiMio: getMultiMioSlice,
    getMultiMioIns: getMultiMioInsSlice,

    // slices for final coating
    getMioListData: getMioListSlice,
    getMioFilterListData: getMioFilterListSlice,
    getMultiFinalCoat: getMultiFinalCoatSlice,
    GetFinalCoatIns: GetFinalCoatInsSlice,

    // issue offer table

    getIssueOfferTable: getIssueOfferTableSlice,
    removeIssueOffTable: removeIssueOffTableSlice,
    manageIssueOffTableSlice: manageIssueOffTableSlice,
    updateIssueOffTable: updateIssueOffTableSlice,

    // Release Note list
    GetMultiReleaseNote: GetMultiReleaseNoteSlice,
    GetMultiGenReleaseNote: GetMultiGenReleaseNoteSlice,

    //packing list
    getMultiPacking: getMultiPackingSlice,
    getMultiPackingList: getMultiPackingListSlice,

    getLoginFirm: getLoginFirmSlice,
    getLoginProject: getLoginProjectSlice,

    getMultiInvoice: getMultiInvoiceSlice,

    getMultiDrawingItems: getMultiDrawingItemsSlice,
    getMultiGrids: getMultiGridsSlice,
  },
});
