import React, { useEffect, useState } from 'react'
import Sidebar from '../../Include/Sidebar';
import Header from '../../Include/Header';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Footer from '../../Include/Footer';
import moment from 'moment';
import axios from 'axios';
import toast from 'react-hot-toast';
import { V_URL } from '../../../../BaseUrl';
import Top from '../../Include/Top';

const QcVerify = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const [disable, setDisable] = useState(false);
  const [errors, setErrors] = useState({});
  const data = location.state;
  // console.log(data, '@@')

  const initialFormValues = data?.items?.map((item) => ({
    acceptedQty: '',
    accepted_lot_no: '',
    acceptedWidth: '',
    acceptedLength: '',
    // acceptedThickness: '',
    accepted_topbottom_thickness: '',
    accepted_normal_thickness: '',
    accepted_width_thickness: '',
    acceptedNos: '',
    tcNo: '',
    rejectedLength: '',
    rejectedWidth: '',
    acceptedRemarks: '',
    manufacture: item?.transactionId?.preffered_supplier?._id,
    rejectedQty: item?.offeredQty || 0,
    transactionId: item?.transactionId?._id
  }));

  const [formValues, setFormValues] = useState(initialFormValues);

  const handleInputChange = (index, name, value) => {
    const newFormValues = [...formValues];
    const newErrors = { ...errors };

    if (name === 'acceptedQty') {
      if (value < 0) {
        newErrors[index] = { ...newErrors[index], acceptedQty: 'Inspected Qty cannot be negative' };
      } else {
        newFormValues[index][name] = value;
        const offeredQty = data?.items[index]?.offeredQty || 0;
        if (value > offeredQty) {
          newErrors[index] = { ...newErrors[index], acceptedQty: 'Inspected Qty cannot exceed Offered Qty' };
        } else {
          newErrors[index] = { ...newErrors[index], acceptedQty: '' };
        }
        newFormValues[index].rejectedQty = offeredQty - value;
      }
    } else {
      newFormValues[index][name] = value;
    }

    if (newFormValues[index].rejectedQty === 0) {
      newErrors[index] = {
        ...newErrors[index],
        rejectedLength: 0,
        rejectedWidth: 0
      };
    }

    setFormValues(newFormValues);
    setErrors(newErrors);
  };

  const handleSubmit = () => {
    const items = formValues.map((values, index) => ({
      rejectedQty: values.rejectedQty,
      acceptedQty: values.acceptedQty,
      accepted_lot_no: values.accepted_lot_no,
      acceptedWidth: values.acceptedWidth,
      acceptedLength: values.acceptedLength,
      // acceptedThickness: values.acceptedThickness,

      accepted_width_thickness: values.accepted_width_thickness,
      acceptedNos: values.acceptedNos,
      accepted_normal_thickness: values.accepted_normal_thickness,
      accepted_topbottom_thickness: values.accepted_topbottom_thickness,

      tcNo: values.tcNo,
      rejectedLength: values.rejectedLength,
      rejectedWidth: values.rejectedWidth,
      acceptedRemarks: values.acceptedRemarks,
      manufacture: values.manufacture,
      transactionId: data?.items[index]?.transactionId?._id,
    }));

    if (validation()) {
      setDisable(true);
      const bodyFormData = new URLSearchParams();
      const myurl = `${V_URL}/user/get-qc-approval`;
      bodyFormData.append('offerId', data?._id);
      bodyFormData.append('acceptedBy', localStorage.getItem('PAY_USER_ID'));
      bodyFormData.append('project', localStorage.getItem('PAY_USER_PROJECT_NAME'))
      bodyFormData.append('items', JSON.stringify(items));

      axios({
        method: 'post',
        url: myurl,
        data: bodyFormData,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: "Bearer " + localStorage.getItem('PAY_USER_TOKEN') }
      }).then((response) => {
        if (response.data.success === true) {
          toast.success(response.data.message);
          navigate('/user/project-store/verify-request-management');
        } else {
          toast.error(response.data.message);
        }
        setDisable(false);
      }).catch((error) => {
        console.log(error, 'error');
        toast.error(error?.resposne?.data?.message || 'Someting went wrong');
        setDisable(false);
      });
    }
  }

  const validation = () => {
    let isValid = true;
    const newErrors = {};

    formValues.forEach((values, index) => {
      if (values.rejectedQty > 0) {
        if (!values.rejectedLength) {
          newErrors[index] = { ...newErrors[index], rejectedLength: 'Please enter rejected length' };
          isValid = false;
        }
        if (!values.rejectedWidth) {
          newErrors[index] = { ...newErrors[index], rejectedWidth: 'Please enter rejected width' };
          isValid = false;
        }
      }

      if (!values.acceptedQty) {
        newErrors[index] = { ...newErrors[index], acceptedQty: 'Please enter inspected qty.' };
        isValid = false;
      } else if (values.acceptedQty < 0) {
        newErrors[index] = { ...newErrors[index], acceptedQty: 'Inspected Qty cannot be negative' };
        isValid = false;
      } else {
        const offeredQty = data?.items[index]?.offeredQty || 0;
        if (values.acceptedQty > offeredQty) {
          newErrors[index] = { ...newErrors[index], acceptedQty: 'Inspected Qty cannot exceed Offered Qty' };
          isValid = false;
        }
      }

      // if (!values.manufacture) {
      //     newErrors[index] = { ...newErrors[index], manufacture: 'Please select manufacture' };
      //     isValid = false;
      // }
    });

    setErrors(newErrors);
    return isValid;
  }

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const handleOpen = () => {
    setIsSidebarOpen(!isSidebarOpen);
  }

  const InputField = ({ label, value, disabled }) => (
    <div className="col-12 col-md-4 col-xl-4">
      <div className="input-block local-forms">
        <label>{label}</label>
        <input className="form-control" value={value} readOnly disabled={disabled} />
      </div>
    </div>
  );

  return (
    <div className={`main-wrapper ${isSidebarOpen ? 'slide-nav' : ''}`}>
      <Header handleOpen={handleOpen} />
      <Sidebar />

      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/user/project-store/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item"><i className="feather-chevron-right"></i></li>
                  <li className="breadcrumb-item">
                    <Link to="/user/project-store/verify-request-management">Material Inspection(QC) List</Link>
                  </li>
                  <li className="breadcrumb-item"><i className="feather-chevron-right"></i></li>
                  <li className="breadcrumb-item active">Manage Material Inspection(QC)</li>
                </ul>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="col-12">
                    <div className="form-heading">
                      <h4>View Request Details</h4>
                    </div>
                  </div>
                  <div className="row">
                    {[
                      { label: 'Request No.', value: data?.requestId?.requestNo },
                      { label: 'Offered No.', value: data?.offer_no },
                      { label: 'Project Location', value: data?.requestId?.storeLocation?.name },
                      { label: 'Request Date', value: moment(data?.requestId?.requestDate).format('YYYY-MM-DD') },
                      { label: 'Material PO No.', value: data?.requestId?.material_po_no },
                      { label: 'Department', value: data?.requestId?.department?.name },
                      { label: 'Approved By', value: data?.requestId?.approvedBy?.name },
                      { label: 'Prepared By', value: data?.requestId?.preparedBy?.user_name },
                    ].map(({ label, value }) => (
                      <div key={label} className="col-12 col-md-4 col-xl-4">
                        <div className="input-block local-forms">
                          <label>{label}</label>
                          <input className="form-control" value={value} readOnly />
                        </div>
                      </div>
                    ))}
                    <div className="col-12 col-md-4 col-xl-4">
                      <div className="input-block local-forms">
                        <p className='m-0' style={{ fontSize: "12px" }}>Status</p>
                        <span className={`custom-badge ${data?.requestId?.status === 1 ? 'status-orange' :
                          data?.status === 2 ? 'status-blue' :
                            data?.status === 3 ? 'status-pink' :
                              data?.status === 4 ? 'status-green' : ''
                          }`}>
                          {data?.status === 1 ? 'Pending' :
                            data?.status === 2 ? 'Approved By Admin' :
                              data?.status === 3 ? 'Rejected By Admin' :
                                data?.status === 6 ? 'Completed' : ''}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <InputField label="Client" value={data?.requestId?.project?.party?.name} />
                    <InputField label="PO / WO No." value={data?.requestId?.project?.work_order_no} />
                    <InputField label="Project PO No." value={data?.requestId?.project?.work_order_no} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  {data?.items?.map((elem, i) =>
                    <form>
                      <div className="col-12">
                        <div className="form-heading">
                          <h4>{i + 1} {elem?.transactionId?.itemName?.name}</h4>
                        </div>
                      </div>

                      <div className='row'>
                        <InputField label="Section Details" value={elem?.transactionId?.itemName?.name} disabled={true} />
                        <InputField label="Material Grade" value={elem?.transactionId?.mcode} disabled={true} />
                        <InputField label="Unit" value={elem?.transactionId?.itemName?.unit?.name} disabled={true} />
                        {/* <InputField label="Stock Qty." disabled={true}
                                                    value={stockData?.find(st => st?.item?._id === elem?.transactionId?.itemName?._id && st?.store_type === elem?.transactionId?.store_type)?.quantity} /> */}
                        <InputField label="Inspection Offer NOS" value={elem?.offerNos} disabled={true} />
                        <InputField label="Inspection Offer UOM" value={elem?.offer_uom?.name} disabled={true} />
                        <InputField label="Inspection Offer Length(mm)" value={elem?.offerLength} disabled={true} />
                        <InputField label="Inspection Offer Width(mm)" value={elem?.offerWidth} disabled={true} />
                        <InputField label="Inspection Offer Date" disabled={true}
                          value={moment(elem?.received_date).format('YYYY-MM-DD')} />
                        <InputField label="Inspection Heat / Lot No." value={elem?.lotNo} disabled={true} />
                        <InputField label="Inspection Offer Thickness (T/B)" value={elem?.offer_topbottom_thickness} disabled={true} />
                        <InputField label="Inspection Offer Thickness (W)" value={elem?.offer_width_thickness} disabled={true} />
                        <InputField label="Inspection Offer Thickness (N)" value={elem?.offer_normal_thickness} disabled={true} />
                        <InputField label="Total Offered Qty.(kg)" value={elem?.offeredQty} disabled={true} />
                        {/* <InputField label="Inspection Offer Thickness" value={elem?.offerThickness} disabled={true} /> */}

                        <div className='col-12 col-md-4 col-xl-4'>
                          <div className="input-block local-forms">
                            <label>Rejected Qty.(kg)</label>
                            <input className="form-control" type='number' name="rejectedQty" value={formValues[i].rejectedQty} disabled />
                          </div>
                        </div>
                        <InputField label="Manufacturer" value={elem?.manufacture?.name} disabled={true} />

                        <div className='col-12 col-md-4 col-xl-4'>
                          <div className="input-block local-forms">
                            <label>Inspected Qty.(kg)</label>
                            <input className="form-control" type='number' name="acceptedQty" value={formValues[i].acceptedQty} onChange={(e) => handleInputChange(i, 'acceptedQty', e.target.value)} />
                            {errors[i]?.acceptedQty && <div className="error">{errors[i]?.acceptedQty}</div>}
                          </div>
                        </div>
                        <div className='col-12 col-md-4 col-xl-4'>
                          <div className="input-block local-forms">
                            <label>Inspected Length(mm)</label>
                            <input className="form-control" type='number' name="acceptedLength" value={formValues[i].acceptedLength} onChange={(e) => handleInputChange(i, 'acceptedLength', e.target.value)} />
                          </div>
                        </div>
                        <div className='col-12 col-md-4 col-xl-4'>
                          <div className="input-block local-forms">
                            <label>Inspected Width(mm)</label>
                            <input className="form-control" type='number' name="acceptedWidth" value={formValues[i].acceptedWidth} onChange={(e) => handleInputChange(i, 'acceptedWidth', e.target.value)} />
                          </div>
                        </div>
                        {/* <div className='col-12 col-md-4 col-xl-4'>
                                                    <div className="input-block local-forms">
                                                        <label>Inspected Thickness</label>
                                                        <input className="form-control" type='number' name="acceptedThickness" value={formValues[i].acceptedThickness} onChange={(e) => handleInputChange(i, 'acceptedThickness', e.target.value)} />
                                                    </div>
                                                </div> */}
                        <div className='col-12 col-md-4 col-xl-4'>
                          <div className="input-block local-forms">
                            <label>Inspected Thickness (T/B)</label>
                            <input className="form-control" type='text' name="accepted_topbottom_thickness" value={formValues[i].accepted_topbottom_thickness} onChange={(e) => handleInputChange(i, 'accepted_topbottom_thickness', e.target.value)} />
                          </div>
                        </div>
                        <div className='col-12 col-md-4 col-xl-4'>
                          <div className="input-block local-forms">
                            <label>Inspected Thickness (W)</label>
                            <input className="form-control" type='text' name="accepted_width_thickness" value={formValues[i].accepted_width_thickness} onChange={(e) => handleInputChange(i, 'accepted_width_thickness', e.target.value)} />
                          </div>
                        </div>
                        <div className='col-12 col-md-4 col-xl-4'>
                          <div className="input-block local-forms">
                            <label>Inspected Thickness (N)</label>
                            <input className="form-control" type='text' name="accepted_normal_thickness" value={formValues[i].accepted_normal_thickness} onChange={(e) => handleInputChange(i, 'accepted_normal_thickness', e.target.value)} />
                          </div>
                        </div>


                        <div className='col-12 col-md-4 col-xl-4'>
                          <div className="input-block local-forms">
                            <label>Inspected NOS </label>
                            <input className="form-control" type='text' name="acceptedNos" value={formValues[i].acceptedNos} onChange={(e) => handleInputChange(i, 'acceptedNos', e.target.value)} />
                          </div>
                        </div>

                        <div className='col-12 col-md-4 col-xl-4'>
                          <div className="input-block local-forms">
                            <label>Heat / Lot No.</label>
                            <input className="form-control" type='text' name="accepted_lot_no" value={formValues[i].accepted_lot_no} onChange={(e) => handleInputChange(i, 'accepted_lot_no', e.target.value)} />
                          </div>
                        </div>
                        <div className='col-12 col-md-4 col-xl-4'>
                          <div className="input-block local-forms">
                            <label>T.C. No.</label>
                            <input className="form-control" type='text' name="tcNo" value={formValues[i].tcNo} onChange={(e) => handleInputChange(i, 'tcNo', e.target.value)} />
                          </div>
                        </div>

                        {/* <div className='col-12 col-md-4 col-xl-4'>
                                                    <div className="input-block local-forms">
                                                        <label>Manufacture <span className="login-danger">*</span></label>
                                                        <select className="form-control form-select" name="manufacture" value={formValues[i].manufacture} onChange={(e) => handleInputChange(i, 'manufacture', e.target.value)}>
                                                            <option value="">Select Manufacture</option>
                                                            {partyData?.map((e) =>
                                                                <option value={e?._id} key={e?._id}>{e?.name}</option>
                                                            )}
                                                        </select>
                                                        <div className="error">{errors[i]?.manufacture}</div>
                                                    </div>
                                                </div> */}
                        {formValues[i].rejectedQty > 0 && (
                          <>
                            <div className='col-12 col-md-4 col-xl-4'>
                              <div className="input-block local-forms">
                                <label>Rejected Length(mm)<span className="login-danger">*</span></label>
                                <input className="form-control" name="rejected_length" value={formValues[i].rejectedLength} onChange={(e) => handleInputChange(i, 'rejectedLength', e.target.value)} />
                                <div className="error">{errors[i]?.rejectedLength}</div>
                              </div>
                            </div>
                            <div className='col-12 col-md-4 col-xl-4'>
                              <div className="input-block local-forms">
                                <label>Rejected Width(mm) <span className="login-danger">*</span></label>
                                <input className="form-control" name="rejected_width" value={formValues[i].rejectedWidth} onChange={(e) => handleInputChange(i, 'rejectedWidth', e.target.value)} />
                                <div className="error">{errors[i]?.rejectedWidth}</div>
                              </div>
                            </div>
                          </>
                        )}
                        <div className='col-12'>
                          <div className="input-block local-forms">
                            <label>Remark</label>
                            <textarea className="form-control" name="acceptedRemarks" />
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                  <div className="col-12">
                    <div className="doctor-submit text-end">
                      <button type="button"
                        className="btn btn-primary submit-form me-2" onClick={handleSubmit} disabled={disable}>{disable ? "Processing..." : 'Submit'}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Top />
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default QcVerify